import React, { useContext, useState } from 'react'
import { useIntl, FormattedHTMLMessage } from 'react-intl'
import Team from 'components/team'
import ReferenceGallery from 'views/about/components/reference-gallery'

import AssociateGallery from 'views/about/components/associate-gallery'
import funds from 'assets/constants/funds'
import { DataContext } from 'components/data-container/data-container'
import messages from './about.messages'

const About: React.FC = () => {
  const [showReferences, setShowReferences] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const { teamMembers, associates, partners, references } = useContext(
    DataContext
  )
  const { formatMessage: f } = useIntl()
  return (
    <div className="row w-100">
      <div className="col-12 about-section d-flex flex-column">
        <div className="row">
          <div className="col-md-6 col-lg border-right-md border-bottom about bg-diagonale px-4 py-5">
            <h1>{f(messages.title)}</h1>
            {/* <blockquote>{f(messages.motto)}</blockquote> */}
            <div
              className="about-text-body"
              style={{
                height: showMore ? 'auto' : 500,
                overflow: 'hidden',
              }}
            >
              {/* <ShowMoreText
                lines={27}
                more={f(messages.more)}
                less={f(messages.less)}
                expanded={showMore}
                onClick={() => setShowMore(prev => !prev)}
              > */}
              <p>
                BLOK A3 d.o.o. djeluje od 2013. godine kao arhitektonsko projektantska i
                konzultantska tvrtka sa sjedištem u Zagrebu. Naša primarna aktivnost je
                pružanje usluga u prostornom planiranju i projektiranju (zgrade
                stambene, poslovne, turističke i javne namjene, uključujući vođenje
                projekata tijekom cjelokupnog procesa, od faze analiza i idejnih
                rješenja, preko izrade kompletne projektne dokumentacije, pa do
                ishođenja potrebnih dozvola za gradnju), te vođenju stručnog nadzora.
              </p>
              <p>
                Kod projektiranja pratimo trendove u promišljanju arhitekture, koristimo
                nove materijale i tehnologiju gradnje, s naglaskom na ostvarivanje nisko
                energetskih svojstava objekta. Vjerujemo da svaki projekt koji nam je
                povjeren, zaslužuje individualan pristup i puni kreativni angažman našeg
                ureda, kao i da je naš cilj postignut jedino ako je u radu na projektu
                ostvareno uzajamno povjerenje s Naručiteljem, a konačnim rezultatom su
                zadovoljni i Naručitelj i naš projektni tim.
              </p>
              <p>
                Ponosni smo na naše realizirane projekte, kao i na one koji se upravo
                realiziraju. Vjerujemo da upravo izvedeni objekti čine razliku i ukazuju
                na kontinuitet našeg rada, kao i upornost i ozbiljnost u ostvarivanju
                naših ideja, uz sve izazove koje proces projektiranja i građenja donosi.
                Tijekom godina rada sastavili smo uhodani i iskusni projektni tim unutar
                samog ureda i ostvarili kvalitetnu i dugoročnu suradnju s našim stalnim
                vanjskim stručnim suradnicima.
              </p>
              <p>
                Od 2017. godine naša tvrtka aktivno prati i sudjeluje u natječajima koji
                se raspisuju kroz programe EU i nacionalnih fondova te u sklopu njih
                izrađujemo i pripremamo projektno-tehničku dokumentaciju. Uz svoje
                stručne suradnike bavimo se i provedbom projekata, izradom
                investicijskih studija i poslovnih planova potrebnih za realizaciju
                kredita, izradom studija pred-izvedivosti, izvedivosti icost-benefit
                analize te vršimo prijave na razne natječaje. U dosadašnjem poslovanju
                BLOK A3 d.o.o. je za svoje klijente pripremio 50 projekata koji su
                financirani iz europskih fondova na području Republike Hrvatske.
              </p>
              <h6>BIM</h6>
              <p>
                Naš poslovni proces od 2019. godine bazira se na BIM sustavu (Building
                Information Modeling), kojeg smo prepoznali kao najnapredniji koncept
                multidisciplinarnog projektiranja odnosno suradnje sa svim stručnim
                suradnicima kroz sve faze projekta istovremeno. Projektiranje se vrši u
                realnom vremenu na jednom mjestu (jedna datoteka) stoga su ažuriranja na
                projektu i napredak svih projektnih timova pod konstantnim nadzorom.
                Prednosti koje projektiranje u BIM sustavu nudi su veća efikasnost,
                predvidljivost i konzistencija projekta, zahvaljujući objedinjavanju
                svih struka i mnoštvo informacija kroz jedan software odnosno datoteku.
                Istovremeno, klijenti i projektni menadžment dobivaju mogućnost dubinske
                analize i efikasnog praćenja projekta koristeći se istom bazom BIM
                sustava u kojem sada razvijamo sve naše projekte. Implementacija BIM-a
                omogućava nam koordinirani rad u realnom vremenu na istom projektu u
                uredima na različitim lokacijama. Prezentacija ili predstavljanje
                projekta preko BIM-a puno je jednostavnija radi njegovog sučelja, a
                omogućava nam prezentaciju preko tableta ili mobitela.
              </p>
              <p>
                Svakim novim projektom težimo unaprijeđenju usklađenosti svih sudionika,
                kontrole procesa i upravljanja promjenama. Obzirom na višegodišnje
                iskustvo svih članova tima u radu s BIM sustavom, prilagodba i
                unaprijeđenje poslovnih procesa u BIM-u nametao se kao logičan slijed u
                našem poslovanju. Upravo tu tranziciju omogućila su nam dobivena
                bespovratna sredstva za realizaciju ulaganja u projekt unaprijeđenja
                poslovnih procesa putem IKT natječaja.
              </p>
              <h6>IKT 2019</h6>
              <p>
                U okviru Opreativnog programa Konkurentnost i kohezija u razdoblju 2014.
                – 2020. BLOK A3 d.o.o. je jedna od 1018 poduzeća koja su dobila
                bespovratna sredstva za unaprijeđenje poslovnih procesa putem IKT
                natječaja za poboljšanje konkurentnosti i učinkovitosti MSP-a kroz
                informacijske i komunikacijske tehnologije (KK.03.2.1.19.1253). Projekt
                je sufinanciran sredstvima Europske unije iz Europskog fonda za
                regionalni razvoj.
              </p>
              <p>
                Ukupna vrijednost projekta je 524.820,14 kuna, ukupno prihvatljivi
                troškovi projekta su 411.903,72 kune, dok su bespovratna sredstva
                267.736,72 kune.
              </p>
              <p>
                Projekt se provodi u razdoblju od 11.10.2019. godine do 11.07.2020.
                godine.
              </p>
              <p>
                Ministarstvo regionalnog razvoja i fondova europske unije
                <br />
                Miramarska cesta 22, 10000 Zagreb, Hrvatska
                <br />
                <a
                  href="mailto:https://razvoj.gov.hr/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://razvoj.gov.hr/
                </a>
              </p>
              <p>
                Posredničko tijelo razine 1:
                <br />
                Ministarstvo gospodarstva, poduzetništva i obrta
                <br />
                Ulica grada Vukovara 78, 10000 Zagreb, Hrvatska
                <br />
                <a
                  href="mailto:https://www.mingo.hr/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://www.mingo.hr/
                </a>
              </p>
              <p>
                Posredničko tijelo razine 2:
                <br />
                Hrvatska agencija za malo gospodarstvo, inovacije i investicije
                <br />
                Ksaver 208, 10000 Zagreb, Hrvatska
                <br />
                <a
                  href="mailto:https://hamagbicro.hr/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://hamagbicro.hr/
                </a>
              </p>
              <p>
                Sadržaj ove publikacije isključiva je odgovornost BLOK A3 d.o.o. i ni na
                koji način ne odražava stajališta EU.
              </p>
              <div className="w-100 d-flex flex-wrap">
              {funds.map(fund => (
                <a
                  key={fund.img}
                  href={fund.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6 p-0 d-flex align-items-center"
                >
                  <img src={fund.img} alt="fund" className="w-100" />
                </a>
              ))}
             
            </div>
             
              <h6 style={{fontWeight: "normal", marginTop: "1rem"}}>DIGITALNA TRANSFORMACIJA 2022.</h6>
              <p style={{margin:0}}>Digitalna transformacija poduzeća Blok A3 d.o.o. - NPOO.C1.1.2.R3-I3.01.0242</p>
              <br/>
              <p className='mb-0'>KORISNIK</p>
              <p className='mb-0'>Blok A3 d.o.o.</p>
              <p className='mb-0'>Prilaz Slave Raškaj 12</p>
              <p className='mb-0'>10000 Zagreb</p>
              <p className='mb-0'>OIB: 46529632639</p>
              <br/><p className='mb-0'>VRIJEDNOST PROJEKTA</p>
              <p className='mb-0'>Ukupna vrijednost: 135.128,14 €</p>
              <p className='mb-0'>Ukupno prihvatljivi troškovi: 127.828,39 €</p>
              <p className='mb-0'>Bespovratna sredstva: 70.305,61 €</p>
              <br/><p className='mb-0'>RAZDOBLJE PROVEDBE</p>
              <p >Od 01.09.2022. do 01.09.2024. godine</p>
              
              <div className="w-100 d-flex flex-wrap">
              
              <p
                 
                  className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6 p-0 d-flex align-items-center"
                >
                  <img src={"/images/eu-new.png"} alt="fund" className="w-100"/>
                </p>
            </div>


              {/* </ShowMoreText> */}
            </div>
            <button
              type="button"
              className="btn btn-link text-lowercase text-capitalize font-weight-light"
              onClick={() => setShowMore(prev => !prev)}
            >
              {showMore ? f(messages.less) : f(messages.more)}
            </button>

            {/* <div className="w-100 d-flex flex-wrap">
              {funds.map(fund => (
                <a
                  key={fund.img}
                  href={fund.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6 p-0 d-flex align-items-center"
                >
                  <img src={fund.img} alt="fund" className="w-100" />
                </a>
              ))}
              <p
                 
                  className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-6 p-0 d-flex align-items-center ml-4"
                >
                  <img src={"/images/eu.png"} alt="fund" className="w-100" />
                </p>
            </div> */}
          </div>

          <div className="col-md-6 col-lg-7 border-bottom members bg-diagonale">
            <h5 className="text-center mb-5 font-weight-bold d-md-none">
              {f(messages.teamTitle)}
            </h5>
            <div className="row">
              <Team
                teamMembers={teamMembers.data.filter(member => member.isVip)}
                memberClassNames="col-6 col-lg-6 mb-1 mb-lg-6"
              />
              <Team
                teamMembers={teamMembers.data.filter(member => !member.isVip)}
                size={0.75}
                memberClassNames="col-6 col-sm-4 col-md-6 col-lg-4 mb-4"
              />
            </div>
          </div>
        </div>

        <div className="row flex-grow-1">
          <div className="col-md-6 col-lg border-right-md border-bottom border-bottom-md-0 references bg-diagonale px-4">
            <h2>
              <span>{f(messages.referencesTitle)}</span>
            </h2>
            <ReferenceGallery
              references={
                showReferences ? references.data : references.data.slice(0, 8)
              }
            />
            {references.data.length > 8 && (
              <a
                href="#"
                className="m-3 font-weight-light"
                onClick={() => setShowReferences(prev => !prev)}
              >
                {showReferences ? f(messages.less) : f(messages.more)}
              </a>
            )}
          </div>

          <div className="col-md-6 col-lg-7 partners bg-diagonale px-4">
            <h2>
              <span>{f(messages.partnersTitle)}</span>
            </h2>

            <div className="mb-8 w-100 ">
              <AssociateGallery associates={associates.data} />
            </div>

            <h2>{f(messages.associatesTitle)}</h2>

            <div className="w-100 mb-7">
              <div className="row">
                {partners.data.map(partner => (
                  <a
                    key={partner.image}
                    href={partner.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="col-6 col-sm-4 col-md-6 col-xl-3 justify-content-center"
                  >
                    <img
                      src={partner.image}
                      alt="partner"
                      className="partner-img  mx-auto"
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
